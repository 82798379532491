@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}
.App {
  position: relative;
  overflow-x: hidden;
}

.slide {
  position: relative;
}
.slide.client-logos-wrapper {
  height: 180px;
  padding: 0 var(--pad-side);
}
.site-footer-wrapper {
  height: unset;
}

/* COLLAGE SECTION */
/* COLLAGE SECTION */

.collage-wrapper {
  position: relative;
}
.collage {
  z-index: -100;
}
.collage-photo {
  position: fixed;
  min-height: 1250px;
  height: 103vh;
  top: -20px;
}

.collage:nth-child(1) {
  clip-path: circle(350px at 90vw 307vh);
}
.collage-photo.first {
  right: -100px;
}
/* MIDDLE LEFT */
.collage:nth-child(2) {
  clip-path: circle(350px at 15vw 170vh);
}
/* MIDDLE LEFT */
.collage-photo.second {
  left: -200px;
}
/* TOP RIGHT */
.collage:nth-child(3) {
  clip-path: polygon(100vw 10vh, 70vw 90vh, 100vw 150vh);
}
.collage-photo.third {
  right: 0;
}

/* COLLAGE SECTION */
/* COLLAGE SECTION */
.slide.slogan {
  color: var(--pink);
  padding-right: 50vw;
  position: relative;
  margin-left: 150px;
  margin-bottom: max(30vh, 250px);
  min-height: 1300px;
  /* margin-bottom: 300px; */
}
#outside-quotation {
  left: -90px;
  position: absolute;
}
.removed {
  display: none;
}
.logo-slider {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 200px;
  width: 95vw;
  margin: 0 auto;
  position: relative;
}

.logo-slider:nth-child(1) {
  margin-top: 10vh;
}

.slide.optk-logo {
  padding: 300px var(--pad-side) 0;
  height: unset;
  display: flex;
  justify-content: center;
  gap:200px;
}

.logos-wrapper {
  display: flex;
  /* margin: 0 43px; */
  flex-direction: column;
  align-items: center;
}
.logos-wrapper img:last-child {
  margin-top: 50px;
}
.slider-image {
  /* transition: all 1s ease-out; */
  /* position: absolute; */
  display: block;
  height: 220px;
  object-fit: cover;
  right: calc(50vw - 220px);
  object-position: top center;
  width: 415px;
}
.slider-content {
  position: relative;
  width: 415px;
  text-align: justify;
}
.slider-content p {
  margin: 60px 0;
}
.triangles-logo {
  display: block;
  margin: 6rem auto 0 auto;
  position: relative;
  width: 300px;
}

.slide.form-wrapper {
  height: fit-content;
}
.slide.sliders {
  /* height: 50vh; */
  height: fit-content;
  padding: 100px 0 300px 0;
  /* transition: all 2s ease-out; */
}
.slider-image:nth-of-type(2) {
  object-position: bottom center;
}
.enter-link {
  background: var(--pink);
}
.triangles-wrapper {
  cursor: pointer;
  text-align: center;
  width: fit-content;
  margin: 1rem auto;
}
.appear-opacity {
  /* transition: all 0.5s linear 1s; */
  opacity: 1;
}

.slide.main-content {
  margin: 30vh 0 40vh 0;
  padding: 0 var(--pad-side);
}

.slide.main-content h2 {
  margin-bottom: 3rem;
}
.slide.main-content img {
  margin-bottom: 60px;
}
.slide.main-content p {
  margin-bottom: 50px;
}
#smiley {
  font-size: x-large;
}
/* WELCOME PORTAL SECTION */
/* WELCOME PORTAL SECTION */

@media screen and (max-width: 1350px) {
  .collage-photo {
    min-height: 950px;
  }
}
@media screen and (max-width: 1050px) {
 
  .slider-image {
    height: 140px;
    width: 250px;
  }
  .logos-wrapper {
    justify-content: center;
  }
  .slide.optk-logo {
    margin-top: 60vh;
  }
  .slide.main-content {
    margin-top: 50vh;
  }
  .slide.slogan h1 {
    font-size: 20vw;
  }
  .slide.slogan {
    margin-left: 10%;
  }
  #outside-quotation {
    left: -10%;
  }
  .collage:nth-child(3) {
    clip-path: polygon(100vw 0, 50vw 40vh, 100vw 110vh);
  }
}

@media screen and (max-width: 850px) {
  .slide.main-content {
    margin-top: 60vh;
  }
  .triangles-logo {
    margin: 3rem auto 0 auto;
  }
  .collage-photo.first {
    right: -250px;
  }
  .collage:nth-child(1) {
    clip-path: circle(250px at 85vw 300vh);
  }
  .collage:nth-child(2) {
    clip-path: circle(280px at 15vw 140vh);
  }
  .slide.slogan h1 {
    font-size: 20vw;
    font-weight: bold;
    line-height: 1.5;
  }
  .slide.slogan {
    min-height: 1200px;
    margin-left: 10vw;
  }
  #outside-quotation {
    left: -9vw;
  }
  .collage-photo.second {
    left: -180px;
  }

  .collage-photo.third {
    right: -25vw;
  }
  .slide.optk-logo {
    margin-top: 0;
    text-align: center;
    flex-direction: column-reverse;
    align-items: center;
    gap: 75px;
  }
  .logos-wrapper img:last-child {
    margin-top: 5px;
  }
  .slider-content {
    width: unset;
  }
}

@media screen and (max-height: 750px) {
  .collage:nth-child(1) {
    clip-path: circle(280px at 85vw 3000px);
  }
  .collage:nth-child(2) {
    clip-path: circle(250px at 15vw 1500px);
  }
  .slide.main-content {
    margin-top: 60vh;
  }
  /*
  .collage-photo.first {
    right: -50vh;
  }
  .collage-photo.second {
    left: -10px;
  }
  .collage:nth-child(3) {
    clip-path: polygon(100vw 10vh, 70vw 90vh, 100vw 150vh);
  }
  */
}
