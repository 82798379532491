:root {
  /* Fonts */
  --font-1: "Roboto", sans-serif;
  --font-2: "Roboto Mono", monospace;
  --font-3: "Helvetica", sans-serif;
  /* Colors */
  --clay: #898989;
  --oatmilk: #d2cfc8;
  --cement: #6d7278;
  --pink: #ed174f;
  /* Spacing */
  --pad-side: 7vw;
}

body {
  overflow-x: hidden;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}
body::-webkit-scrollbar {
  display: none;
}

a {
  color: inherit;
  text-decoration: none;
}

a:hover {
  color: var(--pink);
}
p {
  max-width: 500px;
  font-family: var(--font-2);
  margin-block-end: 0;
  margin-block-start: 0;
  font-size: 18px;
  font-weight: 500;
}
h1,
h2 {
  margin-block-end: 0;
  margin-block-start: 0;
  font-size: 3rem;
  font-family: var(--font-3);
}
h1 {
  font-size: min(300px, 14vw);
  font-size: 220px;
}

/* FORM SUBMISSION */
/* FORM SUBMISSION */
input,
textarea,
button {
  width: unset;
  padding: 0;
  margin: 0;
  border: none;
  box-shadow: none;
  background-color: transparent;
  font-family: inherit;
  font-size: inherit;
  color: inherit;
}

input:focus,
textarea:focus,
button:focus {
  outline: none;
}

input:not([type="file"]) {
  padding-bottom: 0.2em;
  border-bottom: 2px solid black;
}

button:hover,
input[type="button"]:hover,
input[type="submit"]:hover {
  cursor: pointer;
}

button,
input[type="button"],
input[type="submit"] {
  padding: 1em 5em;
  border: none;
  background-color: black;
  color: white;
}
form.h2,
form.h4 {
  margin: unset;
  margin-block-start: unset;
  margin-block-end: unset;
  margin-inline-start: unset;
  margin-inline-end: unset;
  text-align: left;
  font-weight: bold;
  margin-bottom: 3rem;
}
form {
  font-size: 20px;
  margin-bottom: 100px;
}
button {
  padding: 1em 5em;
  color: white;
  background-color: black;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.new-talent-form input {
  width: 250px;
  padding: 10px;
  border-top: none;
  border-left: none;
  border-right: none;
  border-bottom: 2px solid black !important;
}

/* FORM SUBMISSION */
/* FORM SUBMISSION */
